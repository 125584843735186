.list {
  flex: 1;
  overflow: auto;
}

.project {
  margin-top: 0.7em;
  margin-bottom: 0.4em;
}

.projectName {
  font-weight: 500;
}

.projectDescription {
  font-size: 0.9em;
  color: #666;
}

.tasks {
  padding-left: 1em;
  margin-top: 0.5em;
}

.task {
  margin-bottom: 0.5em;
  margin-left: 1em;
}