@import "../../scss/bootstrap";

.controlbar {
  display: flex;
  justify-content: space-between;

  position: sticky;
  bottom: -2.05em;
  z-index: 100;
  margin-bottom: 0;

  padding: 1.2em 2em;

  transition: all .4s cubic-bezier(.47, 1.64, .41, .8);
  border-top: 1px solid transparent;
  background-color: rgba($body-bg, 0);
  background: linear-gradient(180deg, rgba(22, 119, 240, 0.1) 0%, rgba(255, 255, 255, 0) 100%);

  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 1px;
    border-radius: 2px;
    width: 100%;
    top: 0;
    left: 0;
    background-color: $color-primary;
    // border-radius: 0 5px 5px 0;
    // box-shadow: 8px 0 8px rgba(#000, .2);
    opacity: 1;
    transition: all .3s ease;
  }
}

.sticked {

  border-radius: 0;
  border-top: 1px solid $table-border-color ;
  // border-left: 1px solid $table-border-color ;
  // background-color: rgba($body-bg, 0.9);
  // padding: 1.2em 4em;
  // margin-left: -2em;
  // margin-right: -2.2em;
  box-shadow: 40px 0 40px rgb(0 0 0 / 30%);
  transition: all .1s ease-out;
  backdrop-filter: blur(8px);

  &::before {
    opacity: 0;
  }

  // border-radius: 0.5rem 0 0 0;
}