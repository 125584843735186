// https://coolors.co/1cc738-230007-d98324-a40606-5a0002


// https://coolors.co/052d4b-bee9e8-62b6cb-cae9ff-5fa8d3


$color-primary-dark: #052d4b; // green
$color-primary: #1677ff; // blue
$color-secondary: #62B6CB; // blue #5FA8D3
$color-dark: #2D3319; // black
$color-light: #cae9ff; // white
$color-gray: #E2E5F1; // gray

$color-error: #F74459;
$color-warning: #F7A244; // #FFC107;

$color-consumption: #2F97C1; // blue
$color-flow: #587291; // blue


* {
  --color-primary: $color-primary;
  --color-dark: $color-dark;
  --color-gray: $color-gray;
  --color-secondary: $color-secondary;
  --color-light: $color-light;
  --color-error: $color-error;
  --color-warning: $color-warning;
  --color-consumption: $color-consumption;
  --color-flow: $color-flow;
}


/////////////////////
/// Bootstap
/////////////////////

$border-radius: 12px;


$primary: $color-primary;
$dandger: $color-error;
$success: $color-secondary;

// Body
$body-bg: #fff; // lighten($color-light, 5%);
$body-color: $color-dark;
;
// Forms
$input-bg: lighten($color-light, 10%);
$input-border-radius: 7px;
// $input-disabled-bg: $color-gray;
// $input-border-color: #3f4050;
// $input-focus-bg: #1f1d2c;
// $input-group-addon-bg: #3f4050;
// $form-check-input-border: 1px #3f4050 solid;
// $form-check-input-focus-border: green;
// $form-check-input-bg: yellow;
// $form-check-input-indeterminate-color: magenta;
// $form-check-input-border: #fff;
// $form-check-input-bg: #3f4050;
;

$badge-border-radius: 7px;

// $table-border-color: #3f4050;
// $table-striped-bg-factor: .1;
// $table-hover-bg-factor: .2;
$table-th-font-weight: 600;
$table-bg: #fff;

// $nav-link-color: #9f9fb8;
$nav-link-hover-color: $body-color;

$table-group-separator-color: $color-dark;

/////////////////////
/// Custom 
/////////////////////

$box-bg-color: rgba(#fff, 0);
;
//lighten($color-light, 8%);