@import "../../scss/bootstrap";

$paddingTop: 0;
$marginBottom: 0;

.breadcrumbs {
  margin: 0;
  padding: 0;

  // border-bottom: 1px solid rgba($input-border-color, 0);
  background-color: rgba($body-bg, 0);
  transition: all .2s ease;
  font-size: 1.5rem;
  z-index: 100;
  font-weight: 300;

  // background-color: rgba();

  a {
    color: $nav-link-color;
    padding: 0.1em 0;
    text-decoration: none;

    &:hover {
      color: $body-color;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
  }



  .breadcrumb-item {
    // display: block;
    // display: flex;
    // align-items: flex-start;
  }

  // a:first-child {
  //   padding-left: 0;
  // }

  // a::before {
  //   content: '/';
  //   margin: 0 0.2em;
  //   text-decoration: none;
  // }
}

.breadcrumbsList {}

.sticked {
  margin: -0.5rem -2rem $marginBottom -2rem;
  padding: $paddingTop 2rem 0.1rem 2rem;
  // background-color: rgba(#2e303f, 1);
  background-color: rgba($body-bg, 0.9);
  border-bottom: 1px solid $input-border-color;
  box-shadow: 30px 0 30px rgb(0 0 0 / 20%);
  backdrop-filter: blur(15px);
  font-size: 1.2rem;

}