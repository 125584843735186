.id {
    font-family: monospace;
    font-size: 0.8em;
    font-weight: bolder;
    border-radius: 3px;
    background-color: #333;
    color: #fff;
    padding: .2em .3em;
    vertical-align: middle;
    // box-shadow: 1px 1px 2px rgba(blue, .2);
}

.clickable {
    cursor: pointer;
    transition: all .2s ease;
    box-shadow: 0 0 0 0 rgba(#000, 0);

    &:hover {
        box-shadow: 0 0 0 2px rgba(#333, 1);
        background-color: #fff;
        color: #333;
    }
}