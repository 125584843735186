$slot-status-planned-color: #06BEE1;
$slot-status-confirmed-color: #235789;
$slot-status-canceled-color: #DE6E4B;
$slot-status-unavailable-color: #96ADC8;
$slot-status-done-color: #52C41A;


:root {

    --slot-status-planned-color: #{$slot-status-planned-color};
    --slot-status-confirmed-color: #{$slot-status-confirmed-color};
    --slot-status-canceled-color: #{$slot-status-canceled-color};
    --slot-status-unavailable-color: #{$slot-status-unavailable-color};
    --slot-status-done-color: #{$slot-status-done-color};

}