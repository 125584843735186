.loader {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  // backdrop-filter: blur(1px);
  background-color: rgba(#000, .1);
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
  display: none;
  align-items: center;
  justify-content: center;

  >div {
    margin-top: -10%;
  }

  transition: opacity .2s ease .1s;
}

.loaderBar {
  position: fixed;
  // height: 100px;
  width: 100vw;
  top: 0;
  left: 0;
  // backdrop-filter: blur(1px);
  // background-color: rgba(#000, .1);
  z-index: 1000;
  pointer-events: none;
  opacity: 0;
  display: none;
  align-items: center;
  justify-content: center;


  transition: opacity .2s ease .1s;
}

.loader-visible {
  display: flex;
  opacity: 1;
  pointer-events: none;
}