.loader {
    width: 100%;
    height: 10px;
    display: inline-block;
    position: relative;
    background: transparent;
    overflow: hidden;
}

.loader::after {
    content: '';
    width: 192px;
    height: 10px;
    background: #1677ff;
    background: linear-gradient(90deg, rgba(255, 255, 255, .1) 0%, rgba(22, 119, 240, 1) 48%, rgba(255, 255, 255, .2) 100%);
    position: absolute;
    top: -6px;
    left: 0;
    box-sizing: border-box;
    border-radius: 0 0 50% 50%;
    box-shadow: 0 0 10px rgba(22, 119, 240, .9) inset;
    animation: animloader 2s linear infinite;

}

@keyframes animloader {
    0% {
        left: 0;
        width: 10%;
        transform: translateX(-100%);
        background: linear-gradient(90deg, rgba(255, 255, 255, .1) 0%, rgba(#052d4b, 1) 48%, rgba(255, 255, 255, .2) 100%);
    }

    50% {
        left: 0;
        width: 70%;
        transform: translateX(40%);
        background: linear-gradient(90deg, rgba(255, 255, 255, .1) 0%, rgb(61, 181, 241) 48%, rgba(255, 255, 255, .2) 100%);
    }

    100% {
        left: 100%;
        width: 40%;
        transform: translateX(0%);
        background: linear-gradient(90deg, rgba(255, 255, 255, .1) 0%, rgba(22, 119, 240, 1) 48%, rgba(255, 255, 255, .2) 100%);
    }
}