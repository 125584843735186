// @import '@csstools/normalize.css';
// @import "normalize.css";


@import "./bootstrap";


// @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,300;0,400;0,500;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@200;300;400;500;700&display=swap');


body {
  overflow: hidden;
  background-color: $color-primary-dark;
}

.App,
td,
th {
  font-family: 'Noto Sans', sans-serif;
  // font-family: 'Raleway', sans-serif;
  // color: #e6e9ed;
}

.app-grid {
  display: grid;
  // background-color: #1f1d2c;
  // background-color: $body-bg;
  // background-image: linear-gradient(120deg, rgba(#d1fbff, .2) 0%, rgba(#75cfff, .2) 29.13%, rgba(#978aff, .2) 70.56%);
  border-left: 10px solid $color-primary;
  height: 100vh;
  grid-template-columns: 4rem 1fr;
  grid-template-rows: 1fr;
  // grid-template-rows:  100vh;
  grid-template-areas: "sidebar main";

}

.app-sidebar {
  grid-area: sidebar;
  height: 100vh;
  padding: 1.5rem 0;
  background-color: $color-primary-dark;
}

.app-main {
  grid-area: main;
  // padding: 2rem 2rem;
  padding-top: 0;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  // background-color: $color-primary;
  // border-left: 1px solid #3f4050;

  background-color: $body-bg;
  background-image: linear-gradient(120deg, rgba(#d1fbff, .1) 0%, rgba(#75cfff, .1) 29.13%, rgba(#978aff, .1) 70.56%);
}

.app-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: visible;
  // background-color: purple;
  // margin-top: 1rem;

}


@import "./components.scss";

.App h3 {
  font-size: 1.5rem;
  font-weight: 300;
  // color: $color-primary;
}