@import '../../scss/theme.scss';


.messagesView {
  // display: flex;
  // align-items: center;
  // padding: 10px;
  // border-bottom: 1px solid #ccc;

}

.messages {
  overflow: auto;
  max-height: 50vh;
  padding-left: 10px;
  padding-top: 15px;
  // border-bottom: 1px solid #ddd;
  // margin-bottom: 1em;

}

.messageOwned {
  // text-align: right;
}

.messageAuthor {
  font-weight: 500;
  // margin-right: 10px;
}

.messageDate {
  font-size: 0.7rem;
  font-weight: 300;
  color: #999;
}

// .messageDate::before {
//   content: '-';
//   margin: 0 5px;
// }
.noMessages {
  font-style: italic;
  text-align: center;
  color: #999;
  padding: 20px;
  font-size: 0.8em;

}

.messageContent {
  display: inline-block;
  font-style: italic;
  // background-color: rgba(#f9f9f9, .8);

  background: rgb(63, 94, 251);
  background: linear-gradient(160deg, rgba(63, 94, 251, 1) 50%, rgba(135, 70, 252, 1) 100%);
  color: white;
  // border: 1px solid #eee;
  // border-bottom-color: #cac0c0;
  // border-right-color: #e0e0e0;
  padding: 10px 15px;
  border-radius: 0 10px 10px 10px;
  box-shadow: 3px 2px 5px rgba(0, 0, 0, 0.05);
  // color: $color-dark;
}

.messageContext {
  font-size: 0.8em;
  color: #999;
  margin-top: 5px;
}