@import "../../scss/bootstrap";

$paddingTop: 1.5rem;
$marginBottom: 0;



.top0 {
  // position: absolute;
  // top: -2.05rem;
  margin: -0.5rem 0 $marginBottom 0;
  padding: $paddingTop 0 0.1rem 0;
  border-bottom: 1px solid rgba($input-border-color, 0);
  background-color: rgba($body-bg, 0);
  // transition: all .2s ease;
  transition: padding-bottom .2s ease;
  font-size: 1.5rem;
  z-index: 100;
  font-weight: 100;
}


.sticked0 {
  margin: -10px -2rem $marginBottom -2rem;
  padding: $paddingTop 2rem 1rem 2rem;
  // background-color: rgba(#2e303f, 1);
  background-color: rgba($body-bg, 0.9);
  border-bottom: 1px solid $input-border-color;
  box-shadow: 30px 0 30px rgb(0 0 0 / 20%);
  backdrop-filter: blur(15px);
  font-size: 1.2rem;
  font-family: 'Raleway', sans-serif;

}

.top {
  // position: absolute;
  // top: -2.05rem;
  margin: 0 0 $marginBottom 0;
  padding: $paddingTop 1rem 1rem 1rem;
  // background-color: rgba(#2e303f, 1);
  background-color: rgba($body-bg, 0.9);

  border-bottom: 1px solid rgba($input-border-color, 0);
  box-shadow: 30px 0 0 rgb(0 0 0 / 0%);
  background-color: rgba($body-bg, 0);
  // transition: all .2s ease;
  transition: all .1s ease;
  z-index: 100;
  font-weight: 100;
  font-size: 1.2rem;
  font-family: 'Raleway', sans-serif;
}


.sticked {
  border-bottom: 1px solid $input-border-color;
  box-shadow: 30px 0 30px rgb(0 0 0 / 20%);
  background-color: rgba($body-bg, 0.7);
  backdrop-filter: blur(10px);

}

.username {
  font-size: 0.8em;
  font-weight: 300;
  padding: 0 0.5rem;
}

.agency {
  font-size: 0.6em;
  font-weight: 300;
  padding: 0 0.5rem;
  // border-radius: 6px;

  // &:hover {
  //   cursor: pointer;
  //   background-color: $color-primary;
  //   color: #fff;
  // }
}