.container {
  position: relative;

  &:hover {
    .button {
      opacity: 1;
    }
  }
}

.button {
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
  transition: 0.25s opacity ease;
}