.optionTitle {
    margin-right: 0.3em;

    &::after {
        content: ':';
    }
}

.tag {
    margin-right: 3px;
    cursor: pointer;
}