@import "../../scss/theme.scss";

/// Override Antd styles

.ant-drawer-header {
  background-color: $color-primary-dark;

  .ant-drawer-title {
    color: #fff;
  }
}


// Steps

$stepItemWidth: 70px;

.ant-steps-item-content {
  width: $stepItemWidth !important;
}

.ant-steps-item-icon {
  margin-inline-start: ($stepItemWidth - 24)/2 !important;
}

.ant-steps-item-tail {
  margin-inline-start: $stepItemWidth/2 !important;
}