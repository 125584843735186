.event-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  margin-left: 7px;
  text-decoration: none;
  font-size: 0.8em;
}




// .event-canceled {
//   outline: 2px dotted #DE6E4B;
//   background-color: transparent !important;
//   color: #DE6E4B;
// }


.event-light-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  margin-left: 7px;
  text-decoration: none;
  font-size: 0.8em;
}

.event-time {
  border-radius: 4px;
  padding: 2px 5px;
  font-size: 0.8em;
  color: #777;
  // margin-top: -px;
}

.event-error,
.event-warning {
  .event-time {
    border-radius: 4px;
    color: white;
    font-weight: bold;
  }
}

.event-error .event-inner::before,
.event-warning .event-inner::before {
  content: '';
  position: absolute;
  width: 4px;
  height: 85%;
  left: 1px;
  border-radius: 3px;
  top: 2px;
}

.event-error .event-inner::before {
  content: '';
  background-color: red;
}

.event-warning .event-inner::before {
  content: '';
  background-color: orange;
}

.event-error .event-time {
  background-color: red;
}

.event-warning .event-time {
  background-color: orange;
}


@import "../../components/slots/variables.scss";

.slotStatus-planned {
  background-color: adjust-color($slot-status-planned-color, $lightness: 20%, $alpha: -0.5);
  color: scale-color($slot-status-planned-color, $blackness: 90%);
  border-color: transparent; // $slot-status-planned-color;
}

.slotStatus-confirmed {
  background-color: adjust-color($slot-status-confirmed-color, $lightness: 40%, $alpha: -0.3);
  color: scale-color($slot-status-confirmed-color, $blackness: 90%);
  border-color: transparent; // $slot-status-confirmed-color;

  .event-time {
    color: #000;
  }
}


.slotStatus-canceled {
  background-color: adjust-color($slot-status-canceled-color, $lightness: 10%, $alpha: -0.8);
  color: scale-color($slot-status-canceled-color, $blackness: 90%);
  border-color: transparent; // $slot-status-canceled-color;
}

.slotStatus-unavailable {
  background-color: adjust-color($slot-status-unavailable-color, $lightness: 10%, $alpha: -0.5);
  color: scale-color($slot-status-unavailable-color, $blackness: 90%);
  border-color: transparent; // $slot-status-unavailable-color;
}

.slotStatus-done {

  background-color: adjust-color($slot-status-done-color, $lightness: 10%, $alpha: -0.5);
  color: scale-color($slot-status-done-color, $blackness: 90%);
  border-color: transparent; // $slot-status-done-color;
}