@import "../../scss/bootstrap";

$transitionDuration: 0.3s;
$transitionDelay: 0.2s;

.AppSidebar {
  // background-color: $body-bg;
  text-align: center;
  position: fixed;
  z-index: 10;
  overflow: hidden;


  .logo {

    font-weight: normal;
    // margin-top: 1.9em;
    margin: 0 1em;
    margin-bottom: 2em;
    margin-top: -0.3rem;

    img {
      width: 2rem;
      transition: all .3s ease;
      // margin-top: -0.5em;
      // filter: brightness(470%);
      display: block;
    }

    h1 {
      font-size: 1.5em;
      color: $color-primary-dark;
      color: #fff;
      display: block;
      // background: #1677FF;
      // background: linear-gradient(to top right, #1677FF 0%, #052D4B 41%);
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;

    }

    h1,
    h2 {
      margin: 0;
    }



    h2 {
      // display: none;
      font-weight: 300;
      opacity: .7;
      font-size: 1.1rem;
      margin-top: -.25em;
      // padding-left: 4.5rem;
    }

    &::after {
      position: absolute;
      font-size: 0.8em;
      content: '[ rafraîchir ]';
      color: #fff;
      margin-top: 2rem;
      margin-left: 2.5rem;
      opacity: 0;
      transition: all .3s ease;
    }

    &:hover {
      img {
        transform: rotate(90deg);
      }

      &::after {
        opacity: .8;
        margin-top: 3rem;
      }
    }
  }

  hgroup::after {
    content: "";
    display: block;
    height: 3px;
    border-radius: 2px;
    width: 40%;
    background: #1677FF;
    margin: auto;
    margin-top: 0.5em;
    // background: linear-gradient(to top right, #1677FF 0%, #052D4B 41%);
  }

  nav.menu {


    a {
      position: relative;
      display: flex;
      padding: 0.7rem 1rem;
      // color: rgba($color-dark, 0.9);
      color: $color-light;
      text-decoration: none;
      font-size: 1.1rem;
      transition: all .15s ease;
      width: 12rem;

      .icon {
        margin-right: 0.2em;
        width: 1.8em;
        height: 1.8em;
        text-align: center;
        display: inline-block;
        margin-top: -3px;
        padding-top: 1px;
        border-radius: 40%;
        background-color: rgba($color-primary, 0);
        transition: all $transitionDuration ease;
        transition-delay: $transitionDelay;
        opacity: 0.6;
      }


      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        width: 2em;
        margin-left: -3em;
        margin-top: 0.7em;
        background-color: $color-primary;
        // border-radius: 0 5px 5px 0;
        box-shadow: 8px 0 8px rgba(#000, .2);
        left: 0;
        opacity: 0;
        transition: all .3s ease;
      }

      .text {
        flex: 1;
        text-align: left;
      }
    }

    a:hover {
      .icon {
        opacity: 1;
      }
    }

    a.is-active {
      // transform: scale(1.1) ;
      font-weight: 500;

      .icon {

        // background-color: $color-primary;
        // border-radius: 50%;
        // padding: 1em;
        // text-shadow: 0 0 10px rgba(#fff, 0.5);
        transform: scale(1.3);
        opacity: 1;
      }

      svg {
        color: #fff;
      }

      padding-left: 1em;
      color: #fff;

      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        width: 2em;
        margin-left: -1em;
        margin-top: 0.7em;
        background-color: $color-primary;
        // border-radius: 0 5px 5px 0;
        box-shadow: 8px 0 8px rgba(#000, .2);
        left: 0;
        opacity: 1;
      }
    }

    // Submenu
    nav {
      margin-left: 2em;
      margin-top: -0.3em;

      a {
        font-size: 0.9em;
        padding: 0.5rem 1rem;
      }
    }
  }



  .submenu h3 {
    text-align: left;
    text-transform: uppercase;
    font-size: 0.8em;
    padding-left: 1.5em;
    padding-right: 1em;
    opacity: 0.3;
  }


  // For animation


  a .text,
  .logo h1 {
    opacity: 0;
    transform: translateX(2rem);
    transition: all $transitionDuration ease;
    transition-delay: $transitionDelay;
  }

  .icon {
    transform: scale(1);
  }



  width: 4rem;
  transition: width $transitionDuration ease;
  transition-delay: $transitionDelay;
}

.AppSidebar:hover {
  z-index: 1000;

  .logo h1,
  a .text {
    opacity: 1;
    transform: translateX(0);
  }

  a.is-active {
    .icon {
      transform: scale(1.1) !important;
    }
  }



  width: 12rem;
  overflow: auto;

}