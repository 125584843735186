@import "../../scss/theme.scss";

$padding: 1.3rem;

.page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: $color-primary-dark;
}

.page-header {
  // padding: 1rem;
  border-bottom: 1px solid rgba(#000, .1);
  color: #fff;
}

.page-content {
  position: relative;
  padding: $padding;
  flex: 1;
  border-radius: 20px 20px 0 0;
  box-shadow: 0px 0 5px rgba(0, 0, 0, 1);
  background-color: #d1fbff;
  background-image: linear-gradient(120deg, rgba(#d1fbff, .2) 0%, rgba(#75cfff, .2) 29.13%, rgba(#978aff, .2) 70.56%);
}

.page-footer {
  background-color: #E0F6FF;
  padding: 1rem;
  border-top: 1px solid rgba(#000, .1);
  ;
  border-radius: 20px 20px 0 0;

}

.title {
  h1 {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    font-size: 1.2em;

    img {
      height: 1em;
      margin-right: 0.2em;
      filter: brightness(500%);
    }

  }



  padding: 1rem;
  // background-color: rgba(#fff, .6);
  // border-bottom: 1px solid #ddd;
  // border-radius: 0 0 20px 20px;
}

.titleOwnerName {
  font-size: 0.8em;
  color: #ccc;
}

.task {
  // min-height: 100vh;

  // background: radial-gradient(100% 140% at 60% -10%, rgba(255, 255, 255, 0) 0%, #fff 75%);
  h2 {
    font-size: 1.2em;
    font-weight: 400;
    color: $color-primary-dark;
  }

  h3 {
    font-size: 1em;
  }


}


.task-status {
  position: absolute;
  top: -1em;
  right: 1em;

}

.task-param {
  margin-bottom: 0.5rem;

  a {
    text-decoration: none;
  }
}

.task-signature {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 0.8em;

  img {
    background-color: rgba(#fff, .4);
    padding: 1rem;
    border-radius: 10px;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
    max-width: 500px;

    background-image: linear-gradient(155deg, rgba(#ffffff, .3) 33.33%, rgba(#052d4b, .1) 33.33%, rgba(#052d4b, .1) 50%, rgba(#ffffff, .3) 50%, rgba(#ffffff, .3) 83.33%, rgba(#052d4b, .1) 83.33%, rgba(#052d4b, .1) 100%);
    background-size: 70.99px 33.10px;
  }
}


.task-param-icon {
  color: $color-primary-dark;
  display: block;
  width: 1.3rem;
  min-width: 1.3em;
  width: 1.3em;
  opacity: 0.7
}

.description {
  margin-top: 1rem;
  color: $color-primary-dark;
  padding: 1rem;
  margin: 0.5rem -0.5rem;
  background-color: rgba(#fff, .6);
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  font-size: 0.9em;
  // font-style: italic;
}

.control-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba($color-primary, .2);
  padding: $padding;
  // margin: 0 -$padding;
  margin-top: 2rem;
  // border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1) inset;
}



.confirm-signature-block {
  position: relative;
  border: 1px dotted #ccc;
  border-radius: 10px;
  overflow: hidden;
  margin: 1rem 0;
  height: 300px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
}

.drawer-control-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: $padding;
  margin: 0;
  background-color: $color-primary-dark;
  color: #fff;
}