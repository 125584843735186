.projectPanel {
  position: relative;
  display: flex;
  background-color: #fff;
  border-radius: 1rem 0 0 0;
}

.projectPanelInner {
  display: flex;
  width: 400px;
  flex: 1;
  font-size: 0.9em;
  padding: 20px;
  background-color: #fff;
  border-radius: 1rem;

}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: #fff;
}

.fullscreenBox {
  border-radius: 0;
}

@media screen and (max-width: 1800px) {
  .projectPanel {
    width: 20px;
    margin-right: 5px;
    margin-left: 5px;
    background-color: #052d4b;
    border-radius: 1rem;
    height: 90%;
    top: 5%; // overflow: hidden;

    &::after {
      content: 'Affaires';
      position: absolute;
      top: 200px;
      right: -18px;
      // background-color: #333;
      color: white;
      font-size: 1em;
      transform: rotate(-90deg);
      transition: all 0.2s ease-in-out;

    }
  }

  .projectPanelOpen {
    overflow: visible;
  }

  .projectPanelInner {
    overflow: hidden;
    position: absolute;
    z-index: 100;
    right: 0;
    height: 100%;
    width: 0;
    // right: -30px;
    padding: 20px 0;
    // overflow: hidden;
    transition: all 0.2s ease-in-out;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    // border-left: 45px solid #052d4b;

    h3 {
      display: none;
    }


    // &::before {
    //   content: '';
    //   position: absolute;
    //   // background-color: red;
    //   top: 0%;
    //   left: 0;
    //   width: 0%;
    //   height: 100%;
    // }
  }

  .projectPanelInnerOpen {
    right: 0;
    // border-left: 25px solid #052d4b;

    padding: 20px;
    width: 400px;


    h3 {
      display: block;
    }

    &::after {
      right: 95%;

    }

    // &::before {
    //   content: '';
    //   position: absolute;
    //   // background-color: red;
    //   top: 0%;
    //   left: -10%;
    //   width: 120%;
    //   height: 100%;
    // }
  }
}